<script setup lang="ts">
import { defineAsyncComponent } from '@@/bits/vue'
import { OzListRowSeparator } from '@@/library/v4/components/OzWallListRow.vue'
import { useDarkModeStore } from '@@/pinia/dark_mode'
import { ConfirmationDialogLayoutVariant, useGlobalConfirmationDialogStore } from '@@/pinia/global_confirmation_dialog'
import { storeToRefs } from 'pinia'
import { nextTick, ref, watch } from 'vue'

const OzOverlay = defineAsyncComponent(() => import('@@/library/v4/components/OzOverlay.vue'))
const OzConfirmationDialogBox = defineAsyncComponent(
  () => import('@@/library/v4/components/OzConfirmationDialogBox.vue'),
)
const OzDrawerConfirmationDialog = defineAsyncComponent(
  () => import('@@/library/v4/components/OzDrawerConfirmationDialog.vue'),
)
const OzLargeConfirmationDialog = defineAsyncComponent(
  () => import('@@/library/v4/components/OzLargeConfirmationDialog.vue'),
)
const OzWallListRow = defineAsyncComponent(() => import('@@/library/v4/components/OzWallListRow.vue'))

const darkModeStore = useDarkModeStore()
const globalConfirmationDialogStore = useGlobalConfirmationDialogStore()

const { isOpen, confirmationDialogData } = storeToRefs(globalConfirmationDialogStore)
const { isDarkMode } = storeToRefs(darkModeStore)

const { confirm, cancel, discard } = globalConfirmationDialogStore

const dialogBox = ref<InstanceType<typeof OzConfirmationDialogBox>>()

watch(
  isOpen,
  (newIsOpen: boolean): void => {
    if (newIsOpen) nextTick(() => dialogBox.value?.focus())
  },
  { immediate: true },
)
</script>

<template>
  <OzOverlay
    v-if="isOpen && confirmationDialogData.layoutVariant === ConfirmationDialogLayoutVariant.Small"
    data-pw="globalConfirmationDialog"
    data-testid="globalConfirmationDialog"
    class="flex justify-center items-center"
    :dark-mode="isDarkMode"
    :should-fade-in="confirmationDialogData.shouldFadeIn"
    :z-index-class="'z-alert'"
    @scrim-click="cancel"
    @scrim-esc="cancel"
  >
    <OzConfirmationDialogBox
      ref="dialogBox"
      :subject="confirmationDialogData.subject"
      :icon-src="confirmationDialogData.iconSrc"
      :icon-alt="confirmationDialogData.iconAlt"
      :title="confirmationDialogData.title"
      :body="confirmationDialogData.body"
      :is-code-protected="confirmationDialogData.isCodeProtected"
      :confirm-button-text="confirmationDialogData.confirmButtonText"
      :cancel-button-text="confirmationDialogData.cancelButtonText"
      :discard-button-text="confirmationDialogData.discardButtonText"
      :custom-body-classes="confirmationDialogData.customBodyClasses"
      :button-scheme="confirmationDialogData.buttonScheme"
      :force-full-width-buttons="confirmationDialogData.forceFullWidthButtons"
      :x-shadow="confirmationDialogData.xShadow"
      role="dialog"
      :aria-label="confirmationDialogData.title"
      @cancel="cancel"
      @confirm="confirm"
      @discard="discard"
    >
      <template v-if="confirmationDialogData.subject" #subject>
        <div class="px-4 py-2 w-full box-border">
          <OzWallListRow
            v-if="confirmationDialogData.subject.wall"
            :wall="confirmationDialogData.subject.wall"
            :clickable="false"
            :invert-colors="true"
            :separator="OzListRowSeparator.None"
          />
        </div>
      </template>
    </OzConfirmationDialogBox>
  </OzOverlay>
  <OzLargeConfirmationDialog
    v-else-if="isOpen && confirmationDialogData.layoutVariant === ConfirmationDialogLayoutVariant.Large"
    :icon-src="confirmationDialogData.iconSrc"
    :icon-alt="confirmationDialogData.iconAlt"
    :title="confirmationDialogData.title"
    :body="confirmationDialogData.body"
    :confirm-button-text="confirmationDialogData.confirmButtonText"
    :cancel-button-text="confirmationDialogData.cancelButtonText"
    :should-fade-in="confirmationDialogData.shouldFadeIn"
    @confirm="confirm"
    @cancel="cancel"
  />
  <OzDrawerConfirmationDialog
    v-else-if="isOpen && confirmationDialogData.layoutVariant === ConfirmationDialogLayoutVariant.Drawer"
    :icon-src="confirmationDialogData.iconSrc"
    :icon-alt="confirmationDialogData.iconAlt"
    :title="confirmationDialogData.title"
    :body="confirmationDialogData.body"
    :confirm-button-text="confirmationDialogData.confirmButtonText"
    :cancel-button-text="confirmationDialogData.cancelButtonText"
    :should-fade-in="confirmationDialogData.shouldFadeIn"
    @confirm="confirm"
    @cancel="cancel"
  />
</template>
